<template>
  <div class="login_container">
    <div class="login_inner">
      <div class="left_box">
        <h3>Welcome !</h3>
        <div class="text">登录代表您已同意云家心理的
		<span @click="showDialog(1)">《用户服务协议》</span>和<span @click="showDialog(2)">《隐私协议》</span>客服电话：{{config.service_hotline}}</div>
      </div>
      <div class="right_box">
        <div class="right_inner">
          <img src="../assets/images/login3.png" alt="" class="logo">
          <div class="input_wrap">
            <img src="../assets/images/login4.png" alt="" class="icon1">
            <span class="text"><span>+</span>86</span>
            <el-input placeholder="请输入手机号" v-model.trim="phone" clearable></el-input>
          </div>
          <div class="input_wrap">
            <img src="../assets/images/login5.png" alt="" class="icon2">
            <el-input placeholder="请输入密码6-20位字符" :type="isShow ? 'text' : 'password'" v-model.trim="password" clearable></el-input>
            <img src="../assets/images/login6.png" alt="" class="right" @click="isShow = !isShow" v-if="isShow">
            <img src="../assets/images/login7.png" alt="" class="right" @click="isShow = !isShow" v-else>
          </div>
          <router-link to="/register" tag="div" class="link">没有账号? 去注册</router-link>
          <div class="submit" @click="login">登录</div>
        </div>
      </div>
    </div>
	<!-- 用户服务协议 -->
	<el-dialog :title="title" :visible.sync="isCode" :width="device ? '90%' : '500px'">
	  <div class="code_dialog" style="height: 60vh;overflow: auto;">
		  {{content}}
	  </div>
	</el-dialog>
  </div>
</template>


<script>
export default {
  data () {
    return {
      isShow: false,//是否显示明文密码
      phone: '',//手机号
      password: '',//密码
	  isCode:false,
	  config:''
    }
  },
  mounted(){
	  this.$http({
	    method: 'POST',
	    url: '/other/getConfig',
	    data: {}
	  }).then((res)=>{
	  	this.config=res.data
	  })
  },
  methods: {
	  showDialog(type){
		  this.isCode=true
		  if(type==1){
			  this.title='用户服务协议'
			  this.content=this.config.user_service_agreement
		  }else{
			  this.title='用户隐私协议'
			  this.content=this.config.privacy_agreement
		  }
	  },
    //登录
    login () {
      if(!this.phone) {
        this.$message.warning('请输入手机号')
        return
      }
      if(!this.password) {
        this.$message.warning('请输入密码')
        return
      }
	  this.$http({
	    method: 'POST',
	    url: '/Login/MemberLogin',
	    data: {account:this.phone,password:this.password}
	  }).then((res)=>{
	  	this.$message.success('登录成功')
		localStorage.setItem('token',res.token)
		localStorage.setItem('userinfo',JSON.stringify(res.data))
		this.$router.push({name:'my'})
	  })
    }
  }
}
</script>

<style lang="scss">
.login_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background: #F8F7FA url('../assets/images/login1.png') no-repeat center center;
  background-size: 100% 665px;
  .login_inner {
    overflow: hidden;
    display: flex;
    border-radius: 32px;
    .left_box {
      box-sizing: border-box;
      width: 568px;
      height: 695px;
      padding: 0 45px;
      color: #ffffff;
      text-align: center;
      background: #F8F7FA url('../assets/images/login2.png') no-repeat center center;
      background-size: 100% 100%;
      h3 {
        padding-top: 146px;
        padding-bottom: 49px;
        font-size: 60px;
        font-weight: bold;
      }
      .text {
        font-size: 21px;
        font-weight: 400;
        text-align: left;
        line-height: 1.5;
      }
    }
    .right_box {
      box-sizing: border-box;
      width: 640px;
      height: 695px;
      padding-top: 64px;
      background-color: #ffffff;
      .right_inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 460px;
        margin: 0 auto;
        .logo {
          width: 200px;
          height: 91px;
        }
        .link {
          margin-left: auto;
          margin-top: 20px;
          font-size: 16px;
          cursor: pointer;
        }
        .input_wrap {
          box-sizing: border-box;
          display: flex;
          align-items: center;
          width: 100%;
          height: 86px;
          padding-left: 42px;
          padding-right: 42px;
          background-color: #F5F5F5;
          border-radius: 42px;
          margin-top: 50px;
          .text {
            color: #333333;
            font-size: 21px;
            margin: 0 20px;
            span {
              position: relative;
              top: -2px;
            }
          }
          .right {
            cursor: pointer;
          }
          .el-input__inner {
            border: none;
            background: transparent;
          }
        }
        .submit {
          width: 100%;
          height: 86px;
          margin-top: 50px;
          text-align: center;
          line-height: 86px;
          color: #ffffff;
          font-size: 32px;
          font-weight: 500;
          background-color: #FC6076;
          border-radius: 60px;
          cursor: pointer;
          user-select: none;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .login_container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background: #F8F7FA url('../assets/images/login1.png') no-repeat center center;
    background-size: 100% 220px;
    .login_inner {
      overflow: hidden;
      // width: 80%;
      display: flex;
      border-radius: 12px;
      .left_box {
        display: none;
        box-sizing: border-box;
        width: 568px;
        height: 695px;
        padding: 0 45px;
        color: #ffffff;
        text-align: center;
        background: #F8F7FA url('../assets/images/login2.png') no-repeat center center;
        background-size: 100% 100%;
        h3 {
          padding-top: 146px;
          padding-bottom: 49px;
          font-size: 60px;
          font-weight: bold;
        }
        .text {
          font-size: 21px;
          font-weight: 400;
          text-align: left;
          line-height: 1.5;
        }
      }
      .right_box {
        box-sizing: border-box;
        width: auto;
        height: auto;
        padding-top: auto;
        padding: 24px 15px;
        background-color: #ffffff;
        .right_inner {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 0 auto;
          width: 100%;
          .logo {
            width: 100px;
            height: 42px;
          }
          .link {
            margin-left: auto;
            margin-top: 20px;
            font-size: 16px;
            cursor: pointer;
          }
          .input_wrap {
            box-sizing: border-box;
            display: flex;
            align-items: center;
            width: 100%;
            height: 42px;
            padding-left: 24px;
            padding-right: 24px;
            background-color: #F5F5F5;
            border-radius: 42px;
            margin-top: 32px;
            .icon1,
            .icon2 {
              width: 15px;
            }
            .text {
              color: #333333;
              font-size: 16px;
              margin: 0 6px;
              span {
                position: relative;
                top: 0px;
              }
            }
            .right {
              width: 24px;
              cursor: pointer;
            }
            .el-input__inner {
              border: none;
              background: transparent;
            }
          }
          .submit {
            width: 100%;
            height: 42px;
            margin-top: 50px;
            text-align: center;
            line-height: 42px;
            color: #ffffff;
            font-size: 18px;
            font-weight: bold;
            background-color: #FC6076;
            border-radius: 60px;
            cursor: pointer;
            user-select: none;
          }
        }
      }
    }
  }
}
</style>